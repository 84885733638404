export function addSearchUrlParameter(
  name: string,
  value: never,
  checked: boolean
) {
  const searchParams = new URLSearchParams(window.location.search)

  let params: string[] = []

  if (searchParams.has('showAll')) {
    searchParams.set('showAll', value)
  }

  if (searchParams.has('pageNumber')) {
    searchParams.set('pageNumber', '1')
  }

  if (searchParams.has(name)) {
    params = searchParams.get(name)?.split(',') || []
  }

  if (checked) {
    if (!params.includes(value)) {
      params.push(value)
    }
  } else {
    params = params.filter((item) => item !== value)
  }
  if (params.length > 0) {
    searchParams.set(name, params.join(','))
  } else {
    searchParams.delete(name)
  }
  window.location.search = searchParams.toString()
}

export function onSearchSubmit(event) {
  event.preventDefault()
  const searchText = event.target[0].value
  const params = new URLSearchParams()

  if (searchText) {
    params.set('q', searchText)
  }
  window.location.search = params.toString()
}

export function setParam(param: string, value: string) {
  const params = new URLSearchParams(window.location.search)
  params.set(param, value)
  window.location.search = params.toString()
}

export function resetParams() {
  const params = new URLSearchParams(window.location.search)
  params.forEach((value) => params.delete(value))
}
