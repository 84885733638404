import '../styles/main.css'
import './apps'
import {
  addSearchUrlParameter,
  onSearchSubmit,
  setParam,
} from './functions/searchPageFunctions'

window.addEventListener('DOMContentLoaded', () => {
  window.addSearchUrlParameter = addSearchUrlParameter
  window.onSearchSubmit = onSearchSubmit
  window.setParam = setParam
  let offset = 0

  const screenSize = window.innerWidth
  if (screenSize < 1440) {
    offset = 140
  } else {
    offset = 60
  }

  const inPageNavigation = document.getElementById('in-page-navigation')
  const inPageNavigationHight =
    inPageNavigation?.getBoundingClientRect().height || 0
  let offsetAnchor = inPageNavigationHight
  if (screenSize < 1440) {
    offsetAnchor = inPageNavigationHight + 80
  }

  const anchors = document.querySelectorAll('a[data-anchor]')
  let anchorCount = 0

  anchors.forEach((anchor) => {
    anchor.addEventListener('click', function (event) {
      event.preventDefault()
      anchors.forEach(anchor => {
        anchor.classList.remove('active')
      });
      const id = anchor.getAttribute('href')?.substring(1)
      if (id) {
        const target = document.getElementById(id)
        if (target) {
          const top = target.offsetTop - offsetAnchor
          window.scrollTo({
            top: top,
            behavior: 'smooth',
          })
        }
      }
    })
  })

  window.addEventListener('scroll', () => {
    const inPageNavigation = document.getElementById('in-page-navigation')
    const footer = document.getElementById('footer')
    const footerRect = footer?.getBoundingClientRect()


    if (inPageNavigation) {
      anchors.forEach((anchor, index) => {
        const id = anchor.getAttribute('data-anchor')

        if (id) {
          const target = document.getElementById(id)

          if (target) {
            const parent = target.parentNode as HTMLDivElement
            if (parent) {
              const uncle = parent.nextElementSibling
              const screenHeight = window.innerHeight
              if (uncle) {
                const uncleRect = uncle.getBoundingClientRect()
                const rect = target.getBoundingClientRect()

                if (rect.top <= offset * 2 && rect.top >= offset / 2) {
                  if(anchorCount < 1) {
                      anchor.classList.add('active')
                      anchorCount++
                  }
                  inPageNavigation.scrollLeft =
                    (anchor as HTMLElement).offsetLeft - 16
                }

                if (
                  index === anchors.length - 1 &&
                  rect.top <= window.innerHeight / 2 &&
                  footerRect?.top < window.innerHeight
                ) {
                  if(anchorCount < 1) {
                    anchor.classList.add('active')
                    anchorCount++
                }
                  inPageNavigation.scrollLeft =
                    (anchor as HTMLElement).offsetLeft - 16
                }

                if (
                  index === anchors.length - 1 &&
                  rect.top > footerRect?.top - window.innerHeight &&
                  footerRect?.top > window.innerHeight
                ) {
                  if(anchor.classList.contains('active')) {
                    anchor.classList.remove('active')
                    anchorCount--
                  }
                  
                  const anchorElement = anchor as HTMLElement
                  anchorElement.blur()
                }

                if (
                  uncleRect.bottom < offset * 2 ||
                  uncleRect.top > screenHeight
                ) {
                  if(anchor.classList.contains('active')) {
                    anchor.classList.remove('active')
                    anchorCount--
                  }
                  const anchorElement = anchor as HTMLElement
                  anchorElement.blur()
                }
              }
            }
          }
        }
      })
    }
  })

  const debounce = (fn, delay) => {
    let timeoutId = null
    return () => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(fn, delay)
    }
  }

  const throttle = (fn, delay) => {
    let waiting = false
    return () => {
      if (!waiting) {
        fn()
        waiting = true
        setTimeout(() => (waiting = false), delay)
      }
    }
  }

  const reset = (element) => {
    if (element.className !== element.dataset.taosClass) {
      element.className = element.dataset.taosClass
    }
  }
  const before = (element) =>
    (element.className = element.className.replaceAll('taos:', ''))

  const initElement = (element) => {
    if (!element.className.includes('taos-init')) {
      element.dataset.taosClass = element.className + ' taos-init'
      reset(element)
    }
    element.className += ' !duration-[0ms] !delay-[0ms]'

    before(element)

    return {
      element,
      once: getComputedStyle(element)['animation-iteration-count'] === '1',
      offset: parseInt(element.dataset.taosOffset || 0),
    }
  }

  let elements = []
  let innerWidth = window.innerWidth
  let scrollY = window.scrollY

  const refreshTriggers = throttle(() => {
    elements.forEach((el) => {
      let trigger =
        el.element.getBoundingClientRect().top -
        window.innerHeight +
        el.offset +
        scrollY

      if (el.element) {
        const elementCont = el.element.closest('.animation-trigger')
        if (elementCont && elementCont !== el.element) {
          const block = elements.find((elem) => elem.element === elementCont)
          if (block && block.trigger) {
            trigger = block.trigger
          }
        }
      }

      return (el.trigger = trigger)
    })
  }, 0)

  const refresh = () => {
    elements = []
    document
      .querySelectorAll('[class*="taos"]')
      .forEach((el) => elements.push(initElement(el)))
    refreshTriggers()
    requestAnimationFrame(handleScroll)
  }

  window.animationRefresh = () => {
    document.querySelectorAll('[class*="taos"]').forEach((el) => {
      if (!el.className.includes('taos-init')) {
        elements.push(initElement(el))
      }
    })
    refreshTriggers()
    requestAnimationFrame(handleScroll)
    window.scroll()
  }

  const handleScroll = () => {
    scrollY = window.scrollY
    elements.forEach(({ element, trigger, once }) => {
      if (trigger < scrollY) {
        reset(element)
      } else if (!once && element.className?.includes('taos:')) {
        before(element)
      }
    })
    refreshTriggers()
  }

  const handleResize = () => {
    if (innerWidth !== window.innerWidth) {
      innerWidth = window.innerWidth
      refresh()
    }
  }

  refresh()
  addEventListener('scroll', throttle(handleScroll, 32))
  addEventListener('orientationchange', refresh)
  addEventListener('resize', debounce(handleResize, 250))

  const observer = new MutationObserver((mutations) => {
    mutations.forEach(({ target }) => {
      if (
        target.className &&
        !target.className?.includes('taos-init') &&
        target.className?.includes('taos:')
      ) {
        elements.push(initElement(target))
      }
    })
  })
  observer.observe(document, {
    attributes: true,
    childList: true,
    subtree: true,
  })
})
